<template>
  <div
    v-if="loadingPage"
    :style="{
      display: 'flex',
      'justify-content': 'center',
      width: '100%',
      height: '100vh'
    }"
  >
    <SpinnerLoader />
  </div>
  <div
    v-else
    class="d-flex flex-column align-items-center"
  >
  
    <div
      class="page-header clear-filter page-header--success flex order-0"
      filter-color="greenTogreen"
    >
    </div>

<!-- Club logos -->
  <div
      :class="[
        'container d-flex flex-column align-items-center',
        'order-2'
      ]"
    >
      <SpinnerLoader v-if="loadingClubs" />
      <h3 v-else-if="clubs.length === 0">
        No hay clubes disponibles en estos momentos.
      </h3>
      <div v-else>
        <h2 class="title text-center">Nos avalan</h2>
        <div class="d-flex flex-row justify-content-center flex-wrap">
          <ClubLogo
            v-for="federation in federationLogos"
            :key="federation.id"
            :clubLogo="federation.logo"
            :clubUrl="federation.url"
            :clubName="federation.name"
          />
        </div>
        <div class="d-flex flex-row justify-content-center flex-wrap">
          <ClubLogo
            v-for="club in clubLogos"
            :key="club.id"
            :clubLogo="club.logo"
            :clubUrl="club.url"
            :clubName="club.name"
          />
        </div>
      </div>
    </div>  
  
    <!-- VIDEO -->
    <div class="col-12 col-md-6" style="margin:80px 0 20px 0">       
      <iframe width="100%" height="360" src="https://www.youtube.com/embed/T9nKwaFev7I?autoplay=1&controls=0&loop=1" title="Qué es Cluber?" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>

    </div>

    <!-- Club contact form -->

    <div
      :class="[
        'container-fluid d-flex flex-column align-items-center',
        'order-1 my-3',
      ]"
      :style="{
        'background-image': `url(https://a.storyblok.com/f/64023/1866x1244/97bdc042de/header-grass.jpg)`,
        'background-size': 'cover',
        'background-position': 'center',
      }"
    >
      <div class="container-sm" style="max-width: 800px;">
        <div class="d-flex my-5 justify-content-center" id="zf_div_RO-yuKik1RZLiT9YWWrawQA_zZbcmZ4az7m4U-YwHuo"></div>
      </div>
    </div>

  </div>
</template>
<script>
import StoryblokClient from 'storyblok-js-client';
import SpinnerLoader from '../components/SpinnerLoader.vue';
import ClubLogo from '../components/ClubLogo.vue';
import { FormGroupInput, Button } from '@/components';
import pipedriveMixin from '@/mixins/pipedriveMixin';
import { loadScript } from '@/utils/scriptLoader';

import LandingPage from '@/mixins/LandingPage';

const api = new StoryblokClient({
  accessToken: process.env.VUE_APP_ACCESS_TOKEN,
  cache: {
    clear: 'auto',
    type: 'memory',
  },
});

export default {
  components: {
    SpinnerLoader,
    ClubLogo,
    [Button.name]: Button,
    [FormGroupInput.name]: FormGroupInput,
  },
  mixins: [
    pipedriveMixin,
    LandingPage,
  ],
  props: {
    formUrl: String,
    videoUrl: String,
    imageUrl: String,
  },
  data() {
    return {      
      // Form
      name: '',
      mail: '',
      phone: '',
      club: '',
      message: '',
      termsChecked: false,

      // Storyblok
      loadingClubs: true,
      loadingBlogEntries: false,
      loadingCounters: false,
      loadingPage: true,
      blogEntries: [],
      clubs: [],
      clubLogos: [],
      federations: [],
      federationLogos: [],
      
      // Chart data
      chartData: [
        { name: 'Reducción de impagos', rate: '97.68%' },
        { name: 'Patrocinio', rate: '94.06%' },
        { name: 'Inscripción deportistas y socios', rate: '90.09%' },
        { name: 'Gestión automática de cuotas', rate: '88.89%' },
        { name: 'Tienda online', rate: '70.65%' },
        { name: 'Comunicaciones', rate: '65.38%' },
        { name: 'Entradas y control de accesos', rate: '63.25%' },
      ],
      counters: {
        users: '+270.000',
        teams: '+5.000',
        sportsCount: '55'
      },

      // Route params for multiple sports
      extraRouteParam: 'video',
      extraParamsForm: '',
      extraParamsImage: '/img/header-grass.jpg',

      scriptLoaded: false,
    };
  },
  async mounted() {
    //await this.getAcceptedKindOfSports();
    // Detect URL param/query
    this.detectExtraRouteParam();

    // Clubs data
    await this.fetchClubs();
    this.setLogosByUrlParams(this.clubs, 'clubLogos');

    // Federations data
    await this.fetchFederations()
    this.setLogosByUrlParams(this.federations, 'federationLogos');

    // Set proper content based on the previous step
    this.fetchExtraParamData()
    this.loadingPage = false;
    
  },
  watch: {
    loadingPage(current) {
      setTimeout(() => {
        if (!current && !this.scriptLoaded) {
          this.createZohoForm()
          this.scriptLoaded = true;
        }
      }, 200);
    }
  },
  methods: {
    createZohoForm() {
      try{
        var f = document.createElement("iframe");
        f.src = 'https://forms.zohopublic.eu/cluber/form/LandingAntiguaPatrocinio/formperma/RO-yuKik1RZLiT9YWWrawQA_zZbcmZ4az7m4U-YwHuo?zf_rszfm=1';
        f.style.border="none";
        f.style.height="518px";
        f.style.width="90%";
        f.style.transition="all 0.5s ease";
        f.setAttribute("aria-label", 'Patrocinio\x20Cluber');
        var d = document.getElementById("zf_div_RO-yuKik1RZLiT9YWWrawQA_zZbcmZ4az7m4U-YwHuo");
        d.appendChild(f);
        window.addEventListener('message', function (){
        var evntData = event.data;
        if( evntData && evntData.constructor == String ){
          var zf_ifrm_data = evntData.split("|");
          if ( zf_ifrm_data.length == 2 || zf_ifrm_data.length == 3 ) {
            var zf_perma = zf_ifrm_data[0];
            var zf_ifrm_ht_nw = ( parseInt(zf_ifrm_data[1], 10) + 15 ) + "px";
            var iframe = document.getElementById("zf_div_RO-yuKik1RZLiT9YWWrawQA_zZbcmZ4az7m4U-YwHuo").getElementsByTagName("iframe")[0];
            if ( (iframe.src).indexOf('formperma') > 0 && (iframe.src).indexOf(zf_perma) > 0 ) {
              var prevIframeHeight = iframe.style.height;
              var zf_tout = false;
              if( zf_ifrm_data.length == 3 ) {
                iframe.scrollIntoView();
                zf_tout = true;
              }
              if ( prevIframeHeight != zf_ifrm_ht_nw ) {
                if( zf_tout ) {
                setTimeout(function(){
                iframe.style.height = zf_ifrm_ht_nw;
                },500);
                } else {
                iframe.style.height = zf_ifrm_ht_nw;
                }
              }
            }
          }
        }
      }, false);
      }catch(e){}
    },
    fetchExtraParamData() {
      this.extraParamsForm = 'https://webforms.pipedrive.com/f/1AgMcamdPhxy9Koq5yvP2V0Xv0T7qsreWhOVIZCwIgKb3QRZE0H5OaK0FBuemanbt';
      this.extraParamsImage = 'https://a.storyblok.com/f/64023/1866x1244/97bdc042de/header-grass.jpg';
      
    },
    async fetchFederations() {
      const options = {
        starts_with: 'federations/',
        per_page: 100,
      };
      await api.get('cdn/stories', options).then(({ data }) => {
        this.federations = data.stories;
      }).catch(error => {
        this.federations = [];
        throw new Error(error);
      });
    },
    async fetchClubs() {
      const options = {
        starts_with: 'clubs/',
        per_page: 100,
      };
      await api.get('cdn/stories', options)
        .then(({ data }) => {
          this.clubs = data.stories;
          this.loadingClubs = false;
        })
        .catch(error => {
          this.clubs = [];
          throw new Error(error);
        });
    },

    setLogosByUrlParams(source, target) {
      // If any param is set in URL, logos will be filtered looking for the label set in
      // storyblok, otherwise the ones with 'featured' tag will be shown
      // I.E: /success-stories?c=voley will only show the ones with tag voley
      const filteredBySport = source.filter(item => {
        return item.content.label.includes('featured')
      })

      this[target] = filteredBySport.map(item => ({
        logo: item.content.image.filename,
        id: item.id,
        url: '',
        name: item.content.name,
      }));
    },
    getPostThumbnail(post) {
      if (post.content.main_image) {
        return this.$transformImage(post.content.main_image, '0x200');
      } else {
        return '/img/default-article-thumbnail.jpg';
      }
    },
    detectExtraRouteParam() {
      // If any param or query is set on the URL, page should
      // change accordingly to the kind of sport
      const { params, query } = this.$route;
      const kindOfSport = params.kindofsport || query.c;

      // Filter unwanted/unsupported sports to avoid issues
      const isAllowedSport = this.allowedSports.includes(kindOfSport);
      this.extraRouteParam = isAllowedSport
        ? kindOfSport
        : ''; // Empty string uses 'Football' template
    },
  },
};
</script>

<style scoped>
.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.topBar {
  border-top: 2px solid rgb(43, 68, 93);
}

#chart-grid {
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 1em;
}

@media (min-width: 992px) {
  .metric-size {
    width: 30% !important;
  }
}
@media (max-width: 992px) {
  #chart-grid {
    grid-template-columns: auto;
  }
  .chart-text {
    text-align: left;
    margin-left: 0 !important;
    margin-bottom: 0 !important;
    margin-top: 0.5em !important;
  }

  .topBar {
    border-top-width: 0px;
  }
}
.chartBar {
  height: 100%;
  max-height: 2em;
  min-height: 1.5em;
  background-color: #092643;
  animation: load 1.25s ease 0s normal forwards;
}

.chartbar-highlight {
  background-color: #0080FF !important;
}

.chartbar-highlight-icon {
  color: #0080FF;
  font-size: 1.3rem;
  position: relative;
  top: 2px;
}

.page-header--success {
  height: auto !important;
  width: 100%;
}

@keyframes load {
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
}
</style>
