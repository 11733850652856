<script>
import LandingPage from '@/mixins/LandingPage';

export default {
  name: 'sponsorshipbar',
  props: {
    fullWidth: {
      type: Boolean,
      default: false
    },
  },
  mixins: [
    LandingPage
  ],
  data() {
    return {
    };
  },
};
</script>

<template>
  <div
    :class="[
      'align-items-center bg-secondary justify-content-center p-3 px-lg-3 p-lg-0 mx-auto flex-wrap flex-lg-nowrap d-flex',
      fullWidth ? 'col-12' : 'col-11 col-md-12 col-lg-11 rounded-lg'
    ]"
  >
    <span class="rounded-lg bg-danger p-2 mb-3 mb-md-0 text-bold d-none d-md-block"></span>
    <!-- Desktop text -->
    <p class="ml-0 ml-md-4 mb-0 mr-3 mr-md-0 text-bold text-center w-md-auto m-md-0 d-none d-md-block">
      ALTA GRATIS por tiempo limitado 👉 📞
    <a
      href="tel:+34881557165"
    >881-557-165
    </a>
    </p>
    <!-- Mobile text -->
    <p class="ml-1 ml-md-4 mb-0 mr-3 mr-md-0 text-bold text-left text-md-center w-md-auto m-md-0 d-block d-md-none">
      ALTA GRATIS por tiempo limitado 👉 📞
      <a
      href="tel:+34881557165"
    >881-557-165
    </a>
    </p>
  </div>
</template>

<style scoped>
.btn--width {
  min-width: 120px;
}

.alert-mobile {
  width: 30px;
  height: 30px;
  min-width: 30px;
  min-height: 30px;
}
</style>