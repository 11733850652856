var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.loadingPage)?_c('div',{style:({
      display: 'flex',
      'justify-content': 'center',
      width: '100%',
      height: '100vh'
    })},[_c('SpinnerLoader')],1):_c('div',{staticClass:"d-flex flex-column align-items-center"},[_c('div',{staticClass:"page-header clear-filter page-header--success flex order-0",attrs:{"filter-color":"greenTogreen"}}),_c('div',{class:[
        'container d-flex flex-column align-items-center',
        'order-2'
      ]},[(_vm.loadingClubs)?_c('SpinnerLoader'):(_vm.clubs.length === 0)?_c('h3',[_vm._v(" No hay clubes disponibles en estos momentos. ")]):_c('div',[_c('h2',{staticClass:"title text-center"},[_vm._v("Nos avalan")]),_c('div',{staticClass:"d-flex flex-row justify-content-center flex-wrap"},_vm._l((_vm.federationLogos),function(federation){return _c('ClubLogo',{key:federation.id,attrs:{"clubLogo":federation.logo,"clubUrl":federation.url,"clubName":federation.name}})}),1),_c('div',{staticClass:"d-flex flex-row justify-content-center flex-wrap"},_vm._l((_vm.clubLogos),function(club){return _c('ClubLogo',{key:club.id,attrs:{"clubLogo":club.logo,"clubUrl":club.url,"clubName":club.name}})}),1)])],1),_vm._m(0),_c('div',{class:[
        'container-fluid d-flex flex-column align-items-center',
        'order-1 my-3',
      ],style:({
        'background-image': `url(https://a.storyblok.com/f/64023/1866x1244/97bdc042de/header-grass.jpg)`,
        'background-size': 'cover',
        'background-position': 'center',
      })},[_vm._m(1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12 col-md-6",staticStyle:{"margin":"80px 0 20px 0"}},[_c('iframe',{attrs:{"width":"100%","height":"360","src":"https://www.youtube.com/embed/T9nKwaFev7I?autoplay=1&controls=0&loop=1","title":"Qué es Cluber?","frameborder":"0","allow":"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share","allowfullscreen":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-sm",staticStyle:{"max-width":"800px"}},[_c('div',{staticClass:"d-flex my-5 justify-content-center",attrs:{"id":"zf_div_RO-yuKik1RZLiT9YWWrawQA_zZbcmZ4az7m4U-YwHuo"}})])
}]

export { render, staticRenderFns }