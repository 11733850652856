<template>
  <div class="post-page full-height">
    <div class="page-header clear-filter" filter-color="greenTogreen" style="min-height: 128px">
    </div>
    <modal
      :show="isModalVisible"
      footer-classes="d-none"
      body-classes="p-4"
      @close="hideContactModal"
    >
      <div class="section">
        <div
          class="w-100 d-flex justify-content-center"
          id="zf_div_0arvAZsjZ5tWyMvHPfnzBD3AVgY6lUPGmmhfrHSMvuQ">
        </div>
      </div>
    </modal>
    <div class="section">
      <div class="container map-container p-0">
        <div class="ml-auto mr-auto d-flex align-items-start">
          <section :class="searchComponentClassNames" v-touch:swipe="toggleSearchInput">
<!--            <div class="d-flex justify-content-center align-items-center mt-2">-->
<!--              <span><strong>Clubes</strong></span>-->
<!--              <n-switch :value="false" class="m-1" @input="onActivityModeChange"></n-switch>-->
<!--              <span><strong>Actividades</strong></span>-->
<!--            </div>-->
            <button
              class="btn btn-block btn-white bt-md toggle-marker__wrapper mt-0 mb-0 d-xl-none"
            >
              <span class="toggle-marker"></span>
            </button>
            <div class="group w-100" style="margin-top: 56px; margin-bottom: 14px">
              <input v-debounce:2s="searchClubs" id="searchClubs" type="text" v-model="query" required autofocus/>
              <span class="highlight"></span>
              <span class="bar"></span>
              <label for="searchClubs">Club, deporte, ubicación ...</label>
            </div>
            <spinner-loader v-if="loading" class="ml-auto mr-auto d-block"></spinner-loader>
            <div
              v-else
              style="overflow: hidden auto; height: 100%; padding: 0 10px 150px;"
              v-touch:swipe.stop
            >
              <button
                  v-for="club in resultList"
                  :key="club.id"
                  class="btn btn-simple btn-block d-flex align-items-center justify-content-center p-1 search-component__item"
                  :class="isSelected(club.id) ? 'bg-secondary--opacity5 text-white' : ''"
                  @click="onItemSelected(club.id)"
              >
                <div class="club-main__wrapper d-flex text-small">
                  <div class="d-flex club-main">
                    <img :src="club.image" :alt="club.name" class="img-fluid" style="height: 40px;width: auto; object-fit: contain">
                    <div class="d-flex flex-column p-1 text-left">
                      {{ club.name }}
                      <span class="text-gray">
                        {{ club.clubAddress }}
                      </span>
                    </div>
                  </div>
                  <div class="d-flex flex-column text-right align-self-start ml-auto pl-2 pr-2">
                    <span v-for="(sport, index) in getClubSportsArray(club.sports)" class="text-tiny text-gray" :key="index">{{ sport }}</span>
                  </div>
                </div>
              </button>
              <span v-if="!loading && resultList.length === 0 && query && query.length < 3">Introduce al menos 3 caracteres</span>
              <span v-else-if="!loading && resultList.length === 0 && query">No se han encontrado resultados</span>
            </div>
          </section>
          <section class="d-flex flex-column w-100 pt-4 cluber-map" style="flex-grow: 2">
            <button
              v-if="!inputExpanded"
              class="btn btn-secondary position-fixed"
              style="z-index: 1"
              @click="showContactModal"
            >¿Quieres aparecer aquí?</button>
            <c-map :location-data="locationData" ref="cluber-map" @poiSelected="onPoiSelected"></c-map>
          </section>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SpinnerLoader from '../components/SpinnerLoader';
import CMap from '../components/CMap';
import Modal from '../components/Modal';
import { loadScript } from '@/utils/scriptLoader';

const DEFAULT_COORDINATES = { coordinates: { lat: '48.089310', lng: ' -29.135363' }}

export default {
  name: 'SearchClubs',
  components: {
    Modal,
    CMap,
    SpinnerLoader
  },
  data () {
    return {
      loading: false,
      resultList: [],
      query: null,
      position: {
        latitude: 40.085015,
        longitude: -4.096688,
      },
      locationData: [],
      selectedPoi: null,
      inputExpanded: false,
      mapObject: null,
      isModalVisible: false,
      isActivityOn: false
    }
  },
  computed: {
    searchComponentClassNames () {
      return this.inputExpanded ? `search-component search-component--expanded` : `search-component`
    }
  },
  watch: {
    query (newValue, oldValue) {
      if (newValue && newValue.length >= 3) {
        this.loading = true
      }
    }
  },
  created () {
    loadScript('https://webforms.pipedrive.com/f/loader')
  },
  mounted () {
    this.mapObject = this.$refs['cluber-map']
    this.loadZohoForm()
  },
  methods: {
    loadZohoForm() {
      try{
      var f = document.createElement("iframe");
      f.src = 'https://forms.zohopublic.eu/cluber/form/PORTALCluber/formperma/0arvAZsjZ5tWyMvHPfnzBD3AVgY6lUPGmmhfrHSMvuQ?zf_rszfm=1';
      f.style.border="none";
      f.style.height="518px";
      f.style.width="90%";
      f.style.transition="all 0.5s ease";
      f.setAttribute("aria-label", 'Buscador\x20Cluber');
      var d = document.getElementById("zf_div_0arvAZsjZ5tWyMvHPfnzBD3AVgY6lUPGmmhfrHSMvuQ");
      d.appendChild(f);
      window.addEventListener('message', function (){
      var evntData = event.data;
      if( evntData && evntData.constructor == String ){
      var zf_ifrm_data = evntData.split("|");
      if ( zf_ifrm_data.length == 2 || zf_ifrm_data.length == 3 ) {
      var zf_perma = zf_ifrm_data[0];
      var zf_ifrm_ht_nw = ( parseInt(zf_ifrm_data[1], 10) + 15 ) + "px";
      var iframe = document.getElementById("zf_div_0arvAZsjZ5tWyMvHPfnzBD3AVgY6lUPGmmhfrHSMvuQ").getElementsByTagName("iframe")[0];
      if ( (iframe.src).indexOf('formperma') > 0 && (iframe.src).indexOf(zf_perma) > 0 ) {
      var prevIframeHeight = iframe.style.height;
      var zf_tout = false;
      if( zf_ifrm_data.length == 3 ) {
      iframe.scrollIntoView();
      zf_tout = true;
      }
      if ( prevIframeHeight != zf_ifrm_ht_nw ) {
      if( zf_tout ) {
      setTimeout(function(){
      iframe.style.height = zf_ifrm_ht_nw;
      },500);
      } else {
      iframe.style.height = zf_ifrm_ht_nw;
      }
      }
      }
      }
      }
      }, false);
      }catch(e){}
    },
    isSelected (itemId) {
      return itemId == this.selectedPoi
    },
    getClubSportsArray (sports) {
      if (!sports.length) return []
      return sports.map((sport, index) => sports.length < 4 ? sport : index < 3 ? sport : index === 3 ? `+ ${sports.length - index}` : null)
    },
    getJson (response) {
      return new Promise((resolve, reject) => {
        resolve(response.content.toJSON())
      })
        .catch(e => {
          throw new Error('Error parsing JSON response: ' + e)
        })
    },
    async searchClubs (query) {
      const searchTerm = query.trim()
      if (!searchTerm || searchTerm.length < 3) {
        this.resultList = []
        this.loading = false
        return
      }
      fetch(`https://app.cluber.es/api/clubs/search?query=${query}`, {
        headers:{ 'Content-Type': 'application/json' }
      })
        .then(response => response.json())
        .then(data => {
          this.resultList = data.map(item => (item.coordinates.lat && item.coordinates.lng) ? item : {...item, ...DEFAULT_COORDINATES})
          this.locationData = this.resultList
          this.loading = false
        });
    },
    onPoiSelected (item) {
      this.selectedPoi = item
    },
    onItemSelected (clubId) {
      this.selectedPoi = clubId;
      this.mapObject.centerOnClub(clubId);
      this.toggleSearchInputDown()
    },
    toggleSearchInput (obj) {
      this.inputExpanded = !this.inputExpanded
    },
    toggleSearchInputUp () {
      this.inputExpanded = true
    },
    toggleSearchInputDown () {
      this.inputExpanded = false
    },
    blockInput () {
      return
    },
    showContactModal () {
      this.isModalVisible = true
    },
    hideContactModal () {
      this.isModalVisible = false
    },
    onActivityModeChange (value) {
      this.isActivityOn = value
    }
  }
}
</script>

<style scoped lang="scss">
  .map-container {
    max-width: 1920px !important;

  }
  .club-main {
    padding: 0 8px;

    @media (min-width: 1250px) {
      padding: 0 20px;
    }
  }
  .club-main__wrapper {
    padding: 4px;
    width: 100%;
    @media (min-width: 1250px) {
      padding: 8px 12px;
    }
  }
  .cluber-map {
    bottom: 0;
    height: 88vh;
    margin: 0;
    position: fixed;
    @media (min-width: 1250px) {
      height: 70vh;
      margin: 0 32px;
    }
  }
  .search-component {
    background-color: white;
    border-top-right-radius: 25px;
    border-top-left-radius: 25px;
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
    bottom: 0;
    flex-grow: 1.5;
    height: 70vh;
    margin: 0 16px;
    max-height: 220px;
    max-width: calc(100% - 32px);
    overflow: hidden;
    padding: 0px 20px 15px;
    position: fixed;
    width: 100%;
    z-index: 3;
    transition: max-height .3s;
    &--expanded {
      max-height: 80%;
    }

    &__item {
      border: 1px solid lightgrey !important;
      border-radius: 10px;
      background-color: white;
      &:hover {
        background-color: lightgrey !important;
        color: inherit !important;
      }
      @media (min-width: 1250px) {
        border-radius: 50px;
      }
    }
  }
  .toggle-marker {
    top: 0;
    display: block;
    background-color: lightgrey;
    border-radius: 5px;
    overflow: hidden;
    margin: 0 auto;
    height: 5px;
    width: 50%;
    &__wrapper {
      position: fixed;
      margin-top: 0;
      padding: 18px 0;
      width: calc(100vw - 70px);
      z-index: 1033;
    }
  }
  @media (min-width: 1250px) {
    .cluber-map {
      position: relative;
    }
    .search-component {
      background-color: #f6f5f5;
      box-shadow: 0;
      position: relative;
      max-height: 100vh;
      max-width: 100%;
    }
    .club-main__wrapper {
    }
  }
  /* form starting stylings ------------------------------- */
  .group 			  {
    position:relative;
    margin-bottom:45px;
  }
  input 				{
    font-size:18px;
    padding:10px 10px 10px 5px;
    display:block;
    width: 100%;
    border:none;
    border-bottom:1px solid #757575;
  }
  input:focus 		{ outline:none; }

  /* LABEL ======================================= */
  label 				 {
    color:#999;
    font-size:18px;
    font-weight:normal;
    position:absolute;
    pointer-events:none;
    left:5px;
    top:10px;
    transition:0.2s ease all;
    -moz-transition:0.2s ease all;
    -webkit-transition:0.2s ease all;
  }

  /* active state */
  input:focus ~ label, input:valid ~ label 	{
    top:-20px;
    font-size:14px;
    color:#5264AE;
  }

  /* BOTTOM BARS ================================= */
  .bar 	{
    position:relative;
    display:block;
    width: 100%;
  }
  .bar:before, .bar:after 	{
    content:'';
    height:2px;
    width:0;
    bottom:1px;
    position:absolute;
    background: #00a0f8;
    transition:0.2s ease all;
    -moz-transition:0.2s ease all;
    -webkit-transition:0.2s ease all;
  }
  .bar:before {
    left:50%;
  }
  .bar:after {
    right:50%;
  }

  /* active state */
  input:focus ~ .bar:before, input:focus ~ .bar:after {
    width:50%;
  }

  /* HIGHLIGHTER ================================== */
  .highlight {
    position:absolute;
    height:60%;
    width: 100%;
    top:25%;
    left:0;
    pointer-events:none;
    opacity:0.5;
  }

  /* active state */
  input:focus ~ .highlight {
    -webkit-animation:inputHighlighter 0.3s ease;
    -moz-animation:inputHighlighter 0.3s ease;
    animation:inputHighlighter 0.3s ease;
  }

  /* ANIMATIONS ================ */
  @-webkit-keyframes inputHighlighter {
    from { background: #00a0f8; }
    to 	{ width:0; background:transparent; }
  }
  @-moz-keyframes inputHighlighter {
    from { background: #00a0f8; }
    to 	{ width:0; background:transparent; }
  }
  @keyframes inputHighlighter {
    from { background: #00a0f8; }
    to 	{ width:0; background:transparent; }
  }
</style>