<template>
  <div>
    
    <SponsorshipBar
      fullWidth
      v-if="isSponsorshipBannerVisible"
      class="mb-0"
    />
    
    <Navbar
      ref="navBar"
      position="fixed"
      type="white"
      :hasTopSpace="isSponsorshipBannerVisible"
      :topSpaceAmount="getTopMarginForSponsorship"
      :transparent="transparent"
      :color-on-scroll="colorOnScroll"
      menu-classes="ml-auto"
    >
      <template>
        <router-link
          :class="[
            'navbar-brand',
            { 'logo-disabled': isEnclosedLandingPage }
          ]"
          to="/"
        >
          <img
            class="navbar-logo"
            src="../../public/img/cluber_logo.svg"
            alt="Logo Cluber"
          />
        </router-link>
      </template>
      <template slot="navbar-menu">
        <li class="nav-item">

          <router-link
            :to="{ path: '/', hash: 'services' }"
            class="nav-link"
          >
            <p>Soluciones</p>
          </router-link>
        </li>
        <li class="nav-item">
          <router-link
            :to="{ name: 'pricing', params: { reload: true } }"
            class="nav-link"
          >
            <p>Precios</p>
          </router-link>
        </li>
        <li class="nav-item">
          <router-link
            :to="{ name: 'kit-digital', params: { reload: true } }"
            class="nav-link"
          >
            <p>Kit Digital</p>
          </router-link>
        </li>
        <li class="nav-item">
          <router-link
            :to="{ name: 'casos-de-exito', params: { reload: true } }"
            class="nav-link"
          >
            <p>Casos de éxito</p>
          </router-link>
        </li>
        <li
          class="nav-item"
          v-popover:popoverResources
        >
          <span class="nav-link nav-link--dropdown dropdown-toggle">
            Recursos
          </span>
          <el-popover
            ref="popoverResources"
            popper-class="popover"
            placement="top"
            width="500"
            trigger="hover"
          >
            <div class="popover-body">
              <router-link
                :to="{ name: 'categories', params: { reload: true, slug: 'tutorial' } }"
                class="btn btn-block btn-primary"
              >
                Academia
              </router-link>
              <router-link
                :to="{ name: 'categories', params: { reload: true, slug: 'tips' } }"
                class="btn btn-block btn-primary"
              >
                Consejos
              </router-link>
              <router-link
                :to="{ name: 'blog', params: { reload: true } }"
                class="btn btn-block btn-primary"
              >
                Blog
              </router-link>
              <router-link
                :to="{ name: 'Faq' }"
                class="btn btn-block btn-primary"
              >
                Preguntas frecuentes
              </router-link>
            </div>
          </el-popover>
        </li>
        <li class="nav-item">
          <router-link
            :to="{ path: '/', hash: 'contacto' }"
            class="nav-link"
          >
            <p>Contactar</p>
          </router-link>
        </li>
        <li class="nav-item">
          <router-link
            :to="{ name: 'Buscador' }"
            class="nav-link"
          >
            <p>Buscador</p>
          </router-link>
        </li>
        <li class="nav-item">
          <a
            :href="URL_LOGIN_MEMBERS"
            class="nav-link btn btn-round btn-primary btn-simple btn-sm mt-5 mt-lg-2"
            target="_blank"
          >
            Entrar
          </a>
        </li>
        <li class="nav-item ml-1 d-none d-lg-flex">
          <a class="text-gray"
            href="http://www.igape.es/images/PublicidadComunitaria/FEDER-IG190-Re-acciona-2020.png"
            target="_blank"
            rel="noreferrer"
          >
            FEDER
          </a>
        </li>
      </template>
    </Navbar>
  </div>
</template>

<script>
import { Navbar } from "@/components";
import { Tooltip, Popover } from "element-ui";

import SponsorshipBar from '../components/SponsorshipBar.vue';

import { findBootstrapEnvironment } from '../utils/getBootstrapQueryView';

import LandingPageMixin from '@/mixins/LandingPage'

const TIMEOUT = 1;

export default {
  name: "main-navbar",
  props: {
    transparent: Boolean,
    colorOnScroll: Number,
  },
  data() {
    return {
      URL_LOGIN_MEMBERS: "https://app.cluber.es",
      navBarEl: null,
    };
  },
  mixins: [
    LandingPageMixin
  ],
  components: {
    Navbar,
    [Tooltip.name]: Tooltip,
    [Popover.name]: Popover,
    SponsorshipBar,
  },
  computed: {
    getTopMarginForSponsorship() {
      const mobile = '7rem';
      const desktop = '5rem';
      const large = '3rem';

      const margin = {
        xs: mobile,
        sm: desktop,
        md: desktop,
        lg: large,
        xl: large,
      };
      return margin[findBootstrapEnvironment()];
    },
    currentRoute() {
      return this.$route;
    },
    isSponsorshipBannerVisible() {
      //return this.currentRoute.meta.sponsorshipBanner;
      return true;
    },
    isNavBarVisible() {
      return (
        this.navBarEl &&
        this.navBarEl.slotData &&
        !this.navBarEl.slotData.isToggled
      );
    },
  },
  mounted() {
    this.navBarEl = this.$refs.navBar;
  },
};
</script>

<style scoped>
.logo-disabled {
  pointer-events: none;
  user-select: none;
}
</style>
